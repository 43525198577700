import resume from "../assets/pdf/resume.pdf";
import image from "../assets/png/pfp.png";
export const headerData = {
  name: "Ayaan Haider",
  title: "Software engineer",
  desciption:
    "I'm Ayaan, a skilled developer with 5 years of programming experience and 2.5+ years in Flutter. I excel in creating user-friendly, high-quality apps for both Android and iOS, ensuring top-quality results and timely delivery.",
  image: image,
  resumePdf: resume,
};
