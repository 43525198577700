export const experienceData = [
  {
    id: 1,
    company: "Tecerds",
    jobtitle: "Founder",
    startYear: "2024",
    endYear: "Present",
  },
  {
    id: 2,
    company: "Fiverr",
    jobtitle: "Freelancing",
    startYear: "2023",
    endYear: "Present",
  },
  {
    id: 3,
    company: "Upwork",
    jobtitle: "Freelancing",
    startYear: "2023",
    endYear: "Present",
  },
];
