/* eslint-disable */
import { BsCodeSlash } from "react-icons/bs";
import {
  SiAwsamplify,
  SiFirebase,
  SiFlutter,
  SiJavascript,
  SiPostgresql,
  SiTypescript,
} from "react-icons/si";

export const servicesData = [
  {
    id: 1,
    title: "Web Design",
    icon: <BsCodeSlash />,
  },

  {
    id: 2,
    title: "Mobile App Development",
    icon: <SiFlutter />,
  },
  {
    id: 3,
    title: "Backend Development",
    icon: <SiJavascript />,
  },
  {
    id: 4,
    title: "Database Management",
    icon: <SiPostgresql />,
  },
  {
    id: 5,
    title: "Cloud Functions",
    icon: <SiFirebase />,
  },
  {
    id: 6,
    title: "Frontend Development",
    icon: <SiTypescript />,
  },
  {
    id: 7,
    title: "Cloud Services",
    icon: <SiAwsamplify />,
  },
];
