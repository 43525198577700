export const skillsData = [
  "HTML",
  "Figma",
  "Javascript",
  "CSS",
  "React",
  "Next JS",
  "Vue",
  "Angular",
  "Photoshop",
  "Azure",
  "Markdown",
  "Microsoft Office",
  "Adobe XD",
  "After Effects",
  "Bootstrap",
  "C",
  "C++",
  "C#",
  "Python",
  "Java",
  "Swift",
  "Kotlin",
  "PHP",
  "Flutter",
  "Dart",
  "Typescript",
  "Git",
  "MongoDB",
  "Tailwind",
  "ViteJS",
  "MySQL",
  "PostgreSQL",
  "AWS",
  "Firebase",
  "MaterialUI",
  "Nginx",
  "Numpy",
  "Pytorch",
  "Selenium",
  "Tensorflow",
  "Wordpress",
];
