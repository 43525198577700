import adobeXd from "../assets/svg/skills/adobe-xd.svg";
import adobeaudition from "../assets/svg/skills/adobeaudition.svg";
import afterEffects from "../assets/svg/skills/after-effects.svg";
import angular from "../assets/svg/skills/angular.svg";
import aws from "../assets/svg/skills/aws.svg";
import azure from "../assets/svg/skills/azure.svg";
import bootstrap from "../assets/svg/skills/bootstrap.svg";
import c from "../assets/svg/skills/c.svg";
import cplusplus from "../assets/svg/skills/cplusplus.svg";
import csharp from "../assets/svg/skills/csharp.svg";
import css from "../assets/svg/skills/css.svg";
import dart from "../assets/svg/skills/dart.svg";
import figma from "../assets/svg/skills/figma.svg";
import firebase from "../assets/svg/skills/firebase.svg";
import flutter from "../assets/svg/skills/flutter.svg";
import git from "../assets/svg/skills/git.svg";
import html from "../assets/svg/skills/html.svg";
import illustrator from "../assets/svg/skills/illustrator.svg";
import java from "../assets/svg/skills/java.svg";
import javascript from "../assets/svg/skills/javascript.svg";
import kotlin from "../assets/svg/skills/kotlin.svg";
import markdown from "../assets/svg/skills/markdown.svg";
import materialui from "../assets/svg/skills/materialui.svg";
import microsoftoffice from "../assets/svg/skills/microsoftoffice.svg";
import mongoDB from "../assets/svg/skills/mongoDB.svg";
import mysql from "../assets/svg/skills/mysql.svg";
import nextJS from "../assets/svg/skills/nextJS.svg";
import nginx from "../assets/svg/skills/nginx.svg";
import numpy from "../assets/svg/skills/numpy.svg";
import photoshop from "../assets/svg/skills/photoshop.svg";
import php from "../assets/svg/skills/php.svg";
import postgresql from "../assets/svg/skills/postgresql.svg";
import python from "../assets/svg/skills/python.svg";
import pytorch from "../assets/svg/skills/pytorch.svg";
import react from "../assets/svg/skills/react.svg";
import selenium from "../assets/svg/skills/selenium.svg";
import svelte from "../assets/svg/skills/svelte.svg";
import swift from "../assets/svg/skills/swift.svg";
import tailwind from "../assets/svg/skills/tailwind.svg";
import tensorflow from "../assets/svg/skills/tensorflow.svg";
import typescript from "../assets/svg/skills/typescript.svg";
import vitejs from "../assets/svg/skills/vitejs.svg";
import vue from "../assets/svg/skills/vue.svg";
import wordpress from "../assets/svg/skills/wordpress.svg";

export const skillsImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case "html":
      return html;
    case "photoshop":
      return photoshop;
    case "illustrator":
      return illustrator;
    case "adobe xd":
      return adobeXd;
    case "after effects":
      return afterEffects;
    case "css":
      return css;
    case "angular":
      return angular;
    case "javascript":
      return javascript;
    case "next js":
      return nextJS;
    case "react":
      return react;
    case "svelte":
      return svelte;
    case "typescript":
      return typescript;
    case "vue":
      return vue;
    case "bootstrap":
      return bootstrap;

    case "mongodb":
      return mongoDB;
    case "mysql":
      return mysql;
    case "postgresql":
      return postgresql;
    case "tailwind":
      return tailwind;
    case "vitejs":
      return vitejs;

    case "c":
      return c;
    case "c++":
      return cplusplus;
    case "c#":
      return csharp;
    case "dart":
      return dart;
    case "java":
      return java;
    case "kotlin":
      return kotlin;

    case "php":
      return php;
    case "python":
      return python;

    case "swift":
      return swift;
    case "adobe audition":
      return adobeaudition;
    case "aws":
      return aws;
    case "firebase":
      return firebase;
    case "git":
      return git;
    case "materialui":
      return materialui;
    case "nginx":
      return nginx;
    case "numpy":
      return numpy;
    case "pytorch":
      return pytorch;
    case "selenium":
      return selenium;
    case "tensorflow":
      return tensorflow;
    case "wordpress":
      return wordpress;
    case "azure":
      return azure;
    case "figma":
      return figma;
    case "flutter":
      return flutter;
    case "markdown":
      return markdown;
    case "microsoft office":
      return microsoftoffice;
    default:
      break;
  }
};
