import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { headerData } from "../../data/headerData";
import "./Footer.css";

function Footer() {
  const shortname = (name) => {
    if (name.length > 10) {
      return name.split(" ")[0];
    } else {
      return name;
    }
  };

  const { theme } = useContext(ThemeContext);

  return (
    <div className="footer" style={{ backgroundColor: theme.secondary }}>
      <p style={{ color: theme.tertiary }}>
        © {new Date().getFullYear()} by {shortname(headerData.name)} <br />
        Contact:{" "}
        <a
          href="mailto:ayaanhaider.dev@gmail.com"
          style={{ color: theme.primary }}
        >
          ayaanhaider.dev@gmail.com
        </a>
      </p>
    </div>
  );
}

export default Footer;
